/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

import { useHookStore } from './Store'

import "./style.css"


const gotoTele = () => {
    window.open('https://t.me/geckoinuavax')
}
const gotoTW = () => {
    window.open('https://twitter.com/GeckoInuAvax')
}

const LaunchPad = () => {
    let navigate = useNavigate();
    const [state, actions]: any = useHookStore();

    const [selectTab, setSelectTab]: any = useState(0)
    const [listEndIDO, setListEndIDO]: any = useState([])
    const [listUpComingIDO, setListUpComingIDO]: any = useState([])
    const [listOpenIDO, setListOpenIDO]: any = useState([])
    const [listAllIDO, setListAllIDO]: any = useState([])
    const [listTrendIDO, setListTrendIDO]: any = useState([])


    const handleClickTab = (value: any) => {
        if (value === 3) {
            getListIDOEnd()
        }
        if (value === 1) {
            getListIDOUpComing()
        }
        if (value === 2) {
            getListIDOOpen()
        }
        if (value === 0) {
            getListIDOAll()
        }
        setSelectTab(value)
    }

    useEffect(() => {
        getListIDOAll()
        getListTrendIDO()
    }, [])

    const gotoDetails = (symbol: any) => {
        navigate(`${"/launchpad/details"}?${symbol}`);
    }

    const getListTrendIDO = () => {
        try {

            actions.getListTrendIDO().then((res: any) => {
                setListTrendIDO(res)
            })
        } catch (error) {

        }
    }

    const getListIDOAll = () => {
        try {
            let data = {
                lastTime: 0,
                symbol: ""
            }
            actions.getListAllIDO(data).then((res: any) => {
                setListAllIDO(res)
            })
        } catch (error) {

        }
    }

    const getListIDOEnd = () => {
        try {
            let data = {
                lastTime: 0,
                symbol: ""
            }
            actions.getListEndIDO(data).then((res: any) => {
                setListEndIDO(res)
            })
        } catch (error) {

        }
    }
    const getListIDOUpComing = () => {
        try {
            let data = {
                month: 0,
                year: 0
            }
            actions.getListUpComingIDO(data).then((res: any) => {
                setListUpComingIDO(res)
            })
        } catch (error) {

        }
    }
    const getListIDOOpen = () => {
        try {

            actions.getListOpenIDO().then((res: any) => {
                setListOpenIDO(res)
            })
        } catch (error) {

        }
    }

    const handleClickList = () => {
        let elmntToView: any = document.getElementById("viewall");
        if (elmntToView) {
            elmntToView.scrollIntoView({ behavior: "smooth" });
        }
    }

    function handleJoinNow() {
        window.open("https://t.me/geckoinuavax")
    }

    function gotoLink(linkUrl: any): void {
        window.open(linkUrl)
    }

    return (
        <>
            <section className="section-launchpad">
                <div className="main-launchpad">
                    <div className="container-pad">
                        <div className="group-banner">
                            <div className="left">
                                <div className="row-content">
                                    <div className="title-launchpad">
                                        Gecko Inu Launchpad
                                    </div>
                                    <div className="title-big">
                                        The Next Generation Of Launchpad in <span>Avalanche</span>
                                    </div>
                                    <div className="desc">
                                        Full-scale Blockchain Ecosystem for IDOs & NFT Pre-Sales on Avalanche
                                    </div>
                                    <div className="group-button">
                                        <button className="btn btn-project" onClick={handleClickList}>
                                            Project List
                                        </button>
                                        {/* <button className="btn btn-submit">
                                            Submit Project
                                        </button> */}
                                    </div>
                                    <div className="group-community">
                                        <div className="title">
                                            Join Our Community:
                                        </div>
                                        <ul className="list-social">
                                            <li>
                                                <a href="https://t.me/geckoinuavax" target='blank'><img src="../images/tele-lp.png" alt="" /></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/GeckoInuAvax" target='blank'><img src="../images/x-lp.png" alt="" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="box-img">
                                    <div className="img-big">
                                        <img src="./images/launchpad/logo-top.png" alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-arrow">
                <div className="box-img">
                    <img src="./images/img-arrow.png" alt="" />
                    <div className="line-arrow">
                        <img src="./images/line-arrow.png" alt="" />
                    </div>
                </div>
            </section>
            <section>
                <div className="main-trending">
                    <div className="container-pad-renew">
                        <div className="group-content">
                            <div className="left-icon">
                                <img src="./images/left-icon.png" alt="" />
                            </div>
                            <div className="rightt-icon">
                                <img src="./images/right-icon.png" alt="" />
                            </div>
                            <div className="icon-gec-1">
                                <img src="./images/icon-gec-1.png" alt="" />
                            </div>
                            <div className="icon-gec-2">
                                <img src="./images/icon-gec-2.png" alt="" />
                            </div>
                            <div className="top-trending">
                                <div className="icon">
                                    <img src="./images/hot.png" alt="" />
                                </div>
                                <div className="text">
                                    Trending project ?
                                </div>
                            </div>
                            <ul className="list-trending">
                                {listTrendIDO.length !== 0 ? (
                                    <>
                                        {listTrendIDO && listTrendIDO?.map((item: any, index: any) => (
                                            <li>
                                                <div className="logo-item" onClick={() => gotoDetails(item?.keywork)}>
                                                    <img src={item?.logoUrl} alt="" />
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ) : (
                                    <li>
                                        <div className="logo-item">
                                            <img src="./images/launchpad/trend.png" alt="" />
                                        </div>
                                    </li>
                                )}
                            </ul>
                            <div className="box-img-gec">
                                <img src="./images/gec-trend.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-project'>
                <div id="viewall" className='container-project'>
                    <div className="top-project">
                        <div className="text-discover">
                            Discover the hottest projects
                        </div>
                        <div className="tab-project">
                            <div className="list-tab">
                                <div
                                    onClick={() => handleClickTab(0)}
                                    className={`project-launch all ${selectTab === 0 ? 'active' : ''}`}
                                >
                                    All Project
                                </div>
                                <div
                                    onClick={() => handleClickTab(1)}
                                    className={`project-launch upcoming ${selectTab === 1 ? 'active' : ''}`}

                                >Upcoming</div>
                                <div
                                    onClick={() => handleClickTab(2)}
                                    className={`project-launch live ${selectTab === 2 ? 'active' : ''}`}

                                >Live</div>
                                <div
                                    onClick={() => handleClickTab(3)}
                                    className={`project-launch ended ${selectTab === 3 ? 'active' : ''}`}

                                >Ended</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-item-project">
                        {selectTab === 3 ? (
                            <>
                                {listEndIDO.length !== 0 ? (
                                    <>
                                        {listEndIDO.map((item: any, index: any) => (
                                            <div className="item-project-launchpad">
                                                <div className="logo-ido" onClick={() => gotoDetails(item?.keywork)}>
                                                    <img src={item?.claimTokenInfo?.logoUrl} alt="" />
                                                    {/* <img src="./images/launchpad/logo-ido.png" alt="" /> */}
                                                    <div className="ath">
                                                        <div className="img-ath">
                                                            {item?.athRoi >= 100 ? (
                                                                <img src="./images/launchpad/img-ath.png" alt="" />
                                                            ) : (
                                                                <img src="./images/launchpad/img-ath-red.png" alt="" />
                                                            )}

                                                        </div>
                                                        <div className="text-ath">ATH:</div>
                                                        {item?.athRoi >= 100 ? (
                                                            <div className="num-ath">{item?.athRoi}%</div>
                                                        ) : (
                                                            <div className="num-ath-red">{item?.athRoi}%</div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="ido-name" onClick={() => gotoDetails(item?.keywork)}>{item?.name || 'TBA'}</div>
                                                <div className="ido-symbol" onClick={() => gotoDetails(item?.keywork)}>{item?.claimTokenInfo?.symbol || 'TBA'}</div>
                                                <div className="ido-dec" onClick={() => gotoDetails(item?.keywork)}>
                                                    {item?.description || 'TBA'}
                                                </div>
                                                <div className="list-info-ido">
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Audit')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-audit" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                                    <span className='info-ido-audit'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'KYC')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-kyc" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                                    <span className='info-ido-kyc'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Doxx')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-doxx" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                                    <span className='info-ido-doxx'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {/* {item?.documents.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Doxx')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-doxx">
                                                                    <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                                    <span className='info-ido-doxx'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))} */}


                                                    {/* <div className="item-info-ido-kyc">
                                                        <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                        <span className='info-ido-kyc'>KYC</span>
                                                    </div>
                                                    <div className="item-info-ido-doxx">
                                                        <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                        <span className='info-ido-doxx'>Doxx</span>
                                                    </div>
                                                    <div className="item-info-ido-safu">
                                                        <img className='img-safu' src="./images/launchpad/safu.png" alt="" />
                                                        <span className='info-ido-safu'>Safu</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="empty-project-ido">
                                        <div className="coming-soon">
                                            <div className="content-cm">
                                                <button className="btn-com">Coming soon</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : ('')}

                        {selectTab === 1 ? (
                            <>
                                {listUpComingIDO.length !== 0 ? (
                                    <>
                                        {listUpComingIDO.map((item: any, index: any) => (
                                            <div className="item-project-launchpad">
                                                <div className="logo-ido" onClick={() => gotoDetails(item?.keywork)}>
                                                    <img src={item?.claimTokenInfo?.logoUrl} alt="" />
                                                    {/* <img src="./images/launchpad/logo-ido.png" alt="" /> */}
                                                </div>
                                                <div className="ido-name" onClick={() => gotoDetails(item?.keywork)}>{item?.name || 'TBA'}</div>
                                                <div className="ido-symbol" onClick={() => gotoDetails(item?.keywork)}>{item?.claimTokenInfo?.symbol || 'TBA'}</div>
                                                <div className="ido-dec" onClick={() => gotoDetails(item?.keywork)}>
                                                    {item?.description || 'TBA'}
                                                </div>
                                                <div className="list-info-ido">
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Audit')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-audit" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                                    <span className='info-ido-audit'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'KYC')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-kyc" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                                    <span className='info-ido-kyc'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Doxx')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-doxx" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                                    <span className='info-ido-doxx'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {/* <div className="item-info-ido-audit">
                                                        <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                        <span className='info-ido-audit'>Audit</span>
                                                    </div>
                                                    <div className="item-info-ido-kyc">
                                                        <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                        <span className='info-ido-kyc'>KYC</span>
                                                    </div>
                                                    <div className="item-info-ido-doxx">
                                                        <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                        <span className='info-ido-doxx'>Doxx</span>
                                                    </div>
                                                    <div className="item-info-ido-safu">
                                                        <img className='img-safu' src="./images/launchpad/safu.png" alt="" />
                                                        <span className='info-ido-safu'>Safu</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="empty-project-ido">
                                        <div className="coming-soon">

                                            <div className="content-cm">
                                                <button className="btn-com">Coming soon</button>
                                            </div>

                                        </div>
                                    </div>

                                )}
                            </>
                        ) : ('')}

                        {selectTab === 2 ? (
                            <>
                                {listOpenIDO.length !== 0 ? (
                                    <>
                                        {listOpenIDO.map((item: any, index: any) => (
                                            <div className="item-project-launchpad">
                                                <div className="logo-ido" onClick={() => gotoDetails(item?.keywork)}>
                                                    <img src={item?.claimTokenInfo?.logoUrl} alt="" />
                                                    {/* <img src="./images/launchpad/logo-ido.png" alt="" /> */}
                                                </div>
                                                <div className="ido-name" onClick={() => gotoDetails(item?.keywork)}>{item?.name || 'TBA'}</div>
                                                <div className="ido-symbol" onClick={() => gotoDetails(item?.keywork)}>{item?.claimTokenInfo?.symbol || 'TBA'}</div>
                                                <div className="ido-dec" onClick={() => gotoDetails(item?.keywork)}>
                                                    {item?.description || 'TBA'}
                                                </div>
                                                <div className="list-info-ido">
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Audit')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-audit" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                                    <span className='info-ido-audit'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'KYC')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-kyc" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                                    <span className='info-ido-kyc'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Doxx')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-doxx" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                                    <span className='info-ido-doxx'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {/* <div className="item-info-ido-audit">
                                                        <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                        <span className='info-ido-audit'>Audit</span>
                                                    </div>
                                                    <div className="item-info-ido-kyc">
                                                        <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                        <span className='info-ido-kyc'>KYC</span>
                                                    </div>
                                                    <div className="item-info-ido-doxx">
                                                        <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                        <span className='info-ido-doxx'>Doxx</span>
                                                    </div>
                                                    <div className="item-info-ido-safu">
                                                        <img className='img-safu' src="./images/launchpad/safu.png" alt="" />
                                                        <span className='info-ido-safu'>Safu</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="empty-project-ido">
                                        <div className="coming-soon">

                                            <div className="content-cm">
                                                <button className="btn-com">Coming soon</button>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </>
                        ) : ('')}

                        {selectTab === 0 ? (
                            <>
                                {listAllIDO.length !== 0 ? (
                                    <>
                                        {listAllIDO.map((item: any, index: any) => (
                                            <div className="item-project-launchpad">
                                                <div className="logo-ido" onClick={() => gotoDetails(item?.keywork)}>
                                                    <img src={item?.claimTokenInfo?.logoUrl} alt="" />
                                                    {/* <img src="./images/launchpad/logo-ido.png" alt="" /> */}
                                                </div>
                                                <div className="ido-name" onClick={() => gotoDetails(item?.keywork)}>{item?.name || 'TBA'}</div>
                                                <div className="ido-symbol" onClick={() => gotoDetails(item?.keywork)}>{item?.claimTokenInfo?.symbol || 'TBA'}</div>
                                                <div className="ido-dec" onClick={() => gotoDetails(item?.keywork)}>
                                                    {item?.description || 'TBA'}
                                                </div>
                                                <div className="list-info-ido">
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Audit')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-audit" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                                    <span className='info-ido-audit'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'KYC')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-kyc" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                                    <span className='info-ido-kyc'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {item?.documents && item?.documents?.length > 0 && item?.documents
                                                        .filter((doc: any) => doc.name === 'Doxx')
                                                        .map((obj: any, index: any) => (
                                                            <>
                                                                <div className="item-info-ido-doxx" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                    <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                                    <span className='info-ido-doxx'>{obj?.name}</span>
                                                                </div>
                                                            </>
                                                        ))}
                                                    {/* <div className="item-info-ido-audit">
                                                        <img className='img-audit' src="./images/launchpad/audit.png" alt="" />
                                                        <span className='info-ido-audit'>Audit</span>
                                                    </div>
                                                    <div className="item-info-ido-kyc">
                                                        <img className='img-kyc' src="./images/launchpad/kyc.png" alt="" />
                                                        <span className='info-ido-kyc'>KYC</span>
                                                    </div>
                                                    <div className="item-info-ido-doxx">
                                                        <img className='img-doxx' src="./images/launchpad/doxx.png" alt="" />
                                                        <span className='info-ido-doxx'>Doxx</span>
                                                    </div>
                                                    <div className="item-info-ido-safu">
                                                        <img className='img-safu' src="./images/launchpad/safu.png" alt="" />
                                                        <span className='info-ido-safu'>Safu</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="empty-project-ido">
                                        <div className="coming-soon">

                                            <div className="content-cm">
                                                <button className="btn-com">Coming soon</button>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </>
                        ) : ('')}



                        {/* <div className="item-project-launchpad view-all">
                            <div className="view-all-project">
                                View all project
                            </div>
                           
                        </div> */}
                    </div>
                </div>
            </section>
            {/* {isMobile ? (
                <section>
                    <div className="com-mobie">
                        <img src="./images/launchpad/com-mo2.png" alt="" />
                    </div>
                </section>
            ) : ('')} */}
            <section className='section-com'>
                <div className="container-com">
                    <div className="img-com-left">
                        <img src="./images/launchpad/com-left2.png" alt="" />
                        <img className='com-left-before' src="./images/launchpad/com-left1.png" alt="" />
                    </div>
                    <div className="content-com-right">
                        <div className="title-com"><span>Gecko Inu</span> Community</div>
                        <div className="dec-com">
                            There is something for everybody. Follow us and read up on what we are doing. Peace!
                        </div>
                        <div className="action-com">
                            <button onClick={handleJoinNow}>Join now</button>
                            <div className="social-com">
                                <div className="com-tw" onClick={gotoTele}>
                                    <img src="./images/tele-lp.png" alt="" />
                                </div>
                                <div className="com-tele" onClick={gotoTW}>
                                    <img src="./images/x-lp.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default LaunchPad